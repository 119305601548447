import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../logo.png'

const Login = () => {
    const {loginWithRedirect} = useAuth0();

    return (
        <div className="login_form">
        
        <div className="login_form__wrapper">
          <div className="login_form__caption">Having trouble? Contact dylan@prismstudio.io</div>
          <div className="login_form__formelement w-form"><img src={logo} loading="lazy" alt="" className="login_form__logo" />
            <button className="login_form__submit w-button" onClick={() => loginWithRedirect()}>
            Login Now
            </button>
          </div>
        </div>
      </div>
    )
}

export default Login
